<template>
	<div class="">
		<div class="gg-container">
			<div class="search-container">
				<div class="search-container-fn-input">
					<label>问诊编号：</label>
					<el-input size="small" placeholder="请输入问诊编号" prefix-icon="el-icon-search"
										v-model="searchParams.order_sn" clearable @change="getList">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>接诊医师：</label>
					<el-input size="small" placeholder="请输入接诊医师姓名" prefix-icon="el-icon-search"
										v-model="searchParams.doctors_name" clearable @change="getList">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>问诊类型：</label>
					<el-select size="small" placeholder="请选择" v-model="searchParams.type" clearable @change="getList">
						<template slot="prefix">
							<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
						</template>
						<el-option v-for="item in superviseType" :key="item.value" :label="item.label"
											 :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search-container-fn-input">
					<label>患者信息：</label>
					<el-input size="small" placeholder="请输入患者姓名/手机号" prefix-icon="el-icon-search"
										v-model="searchParams.member_keyword" clearable @change="getList">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>问诊时间：</label>
					<el-date-picker v-model="searchParams.created_at" size="small" type="datetimerange" range-separator="至"
													start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" align="left"
													@change="getList">
					</el-date-picker>
				</div>
				<div style="margin-left: auto; display: flex; justify-content: space-around">
					<!--<div class="search-container-fn-input">-->
					<!--	<el-button-->
					<!--		size="mini"-->
					<!--		type="primary"-->
					<!--		style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"-->
					<!--		@click="handleTableHead()">-->
					<!--		<i class="el-icon-crop" style="font-size: 13px"/>-->
					<!--		<span style="margin-left: 5px; font-size: 12px">删选表头</span>-->
					<!--	</el-button>-->
					<!--</div>-->
					<div class="search-container-fn-input">
						<el-button
							size="mini"
							type="primary"
							style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
							@click="handleTableHeadEdit()">
							<i class="el-icon-edit" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">编辑表头</span>
						</el-button>
					</div>
				</div>
			</div>
			<!--table-->
			<el-table border v-loading="listLoading" :data="tableData" style="width: 100%; z-index: 0"
								:header-cell-style="{'text-align':'center'}" :cell-style="cellStyle">
				<template
					v-for="(column,index) in tableHead"
				>
					<el-table-column
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						align="center"
						v-if="column.field_type === 'textBtn' && column.visible === true ">
						<template slot-scope="scope">
							<a style="font-size: 12px; color: #2379fb"
								 @click.prevent="activeName='first';getePrescription(scope.row);getDetail(scope.row)">
								{{scope.row[column.column_prop]}}
							</a>
						</template>
					</el-table-column>
					<el-table-column
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						align="center"
						v-else-if="column.field_type === 'imageBtn' && column.visible === true "
					>
						<template slot-scope="scope">
							<div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
									 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
								<a>点击查看图片</a>
								<el-image
									style="width: 0px; height: 0px"
									:ref="scope.row[column.column_prop][0]"
									:src="imageUrl"
									:preview-src-list="scope.row[column.column_prop]"
									:z-index="9999">
								</el-image>
							</div>
							<div v-else>
								暂无
							</div>
						</template>
					</el-table-column>
					<el-table-column
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						v-else-if="column.visible === true"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							<span v-if="column.column_prop === 'type'">{{scope.row[column.column_prop] === 1 ? '图文问诊' : '视频问诊' }}</span>
							<span v-else-if="column.column_prop === 'from_source'">
								{{from_sources[scope.row[column.column_prop]-1]}}
							</span>
							<span v-else-if="column.column_prop === 'order_status'">
								{{scope.row[column.column_prop] | formatOrderStatus}}
							</span>
							<span v-else-if="column.column_prop === 'supervise_status'">
								{{scope.row[column.column_prop] | formatSuperviseStatus}}
							</span>
							<span v-else>{{scope.row[column.column_prop]}}</span>
						</template>
					</el-table-column>
				</template>
				
				<el-table-column fixed="right" align="center" width="120" label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="danger" icon="el-icon-delete"
											 style="padding: 7px 8px;margin-left: 5px;" @click="handleDelete(scope.$index, scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<!--pagination-->
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
									@pagination="getList"/>
			<!--drawer-->
			<el-drawer v-loading="listLoading" :before-close="handleCloseDrawer" @closeDrawer="handleCloseDrawer"
								 title="" size="76%" :visible.sync="drawerVisible" :with-header="false">
				<therapyDetail @closeDrawer="closeDrawer" :info="info" :id="pres_id" v-if="drawerVisible"
											 :diag_id="diag_id">
				</therapyDetail>
			</el-drawer>
		</div>
		
		<TherapyEditTableScheme :isActiveTherapyEditTableScheme.sync="isActiveTherapyEditTableScheme"
								 @_getAdminFieldIndex="_getAdminFieldIndex"
								 v-if="isActiveTherapyEditTableScheme">
		
		</TherapyEditTableScheme>
	
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import therapyDetail from './components/therapyDetail'
import TherapyEditTableScheme from './components/therapyEditTableScheme'
import {
  getTherapy,
  getTherapyDetail,
  delTherapyById
} from '@/api/informationSearch/index.js';
import {
  ePrescription
} from '@/api/informationSearch/index_2.0.js';
import {getAdminFieldIndex} from '@/api/tableHead';

export default {
  name: "therapy",
  components: {
    Pagination,
    therapyDetail,
    TherapyEditTableScheme
  },
  async created () {
    await this._getAdminFieldIndex()
    this.getList()
  },
  data () {
    return {
      info: {},
      currTherapy: {},
      dataInfo: {},
      pres_id: '', //处方ID
      prescriptionDetail: {}, //电子处方
      chatList: [], //问诊记录列表
      from_sources: ['患者场景', '医生场景', '慢病场景'],
      examine_status: ['待审核', '一审通过', '二审通过', '拒绝'],
      superviseType: [{
        value: '1',
        label: '图文'
      }, {
        value: '2',
        label: '视频'
      }],
      therapyStatus: ['已取消', '待接诊', '接诊中', '已结束'],
      superviseStatus: ['未上传', '待上传', '已上传'],
      isActiveTherapyEditTableScheme: false,
      tableData: [],
      dataDrawerTab1: {},
      dataDrawerTab2: {},
      searchParams: {
        orderTime: []
      },
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      listChatQuery: {
        page: 1,
        limit: 10,
      },
      total: 0,
      listLoading: false,
      drawerVisible: false,
      imageUrl: '',
      tableHead: [],
    }
  },
  filters: {
    formatOrderStatus (v) {
      // 已取消，待接诊，接诊中，已结束
      if (v === 1) {
        return '待接诊'
      } else if (v === 2) {
        return '接诊中'
      } else if (v === 3) {
        return '已取消'
      } else if (v === 4) {
        return '待评价'
      } else {
        return '已完成'
      }
    },
    formatSuperviseStatus (v) {
      // 未上传，待上传，已上传。
      if (v === 1) {
        return '未上传'
      } else if (v === 2) {
        return '待上传'
      } else {
        return '已上传'
      }
    }
  },
  methods: {
    async _getAdminFieldIndex () {
      try {
        this.listLoading = true
        var params = {
          type: 'diagnosis'
        }
        const res = await getAdminFieldIndex(params)
        this.tableHead = res.data
      } catch (err) {
        //在此处理错误
      } finally {
        this.listLoading = false
      }

    },
    getePrescription (row) {
      if (!row.pres_id) {
        return
      } else {
        let params = {
          pres_id: row.pres_id
        }
        ePrescription(params).then(res => {
          if (res.code == 200) {
            this.prescriptionDetail = res.data
            this.prescriptionDetail.w_drugs.forEach(item => {
              item.pres_not ? item.pres_not = '是' : item.pres_not = '否'
            })
            console.log(this.prescriptionDetail, 222)
          }
        })
      }
    },
    getList (rest) {
      if (rest == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      let params = {
        ...this.searchParams
      }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      getTherapy(params).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = Number(_data.per_page)
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    getDetail (row) {
      this.drawerVisible = !this.drawerVisible
      this.diag_id = row.diag_id
      this.pres_id = row.pres_id
      this.listLoading = true
      let params = {
        'diag_id': row.diag_id
      }
      getTherapyDetail(params).then(response => {
        let data = response
        console.log(data)
        if (data.code == 200) {
          this.info = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    cellStyle (row, column, rowIndex, columnIndex) {
      if (row.column.label === "问诊编号") {
        return 'color: #4EBBFC'
      }
    },
    handleDelete (index, row) {
      console.log(index, row);
      this.$confirm('确认删除问诊信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTherapyById(row.diag_id).then(res => {
          if (res.code == 200) {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleShowImage(row, ref) {
      console.log(ref)
      console.log(this.$refs[ref])
      this.$refs[ref][0].clickHandler();
      this.imageUrl = row[0]
		},
    handleCloseDrawer () {
      this.dataDrawer = {};
      this.tableDataDrawer = []
      this.drawerVisible = false
    },
    //关闭弹窗,刷新列表
    closeDrawer (e) {
      this.drawerVisible = e
      this.info = {}
    },
    handleTableHead () {
      this.tableDrawerVisible = true
    },
    handleTableHeadEdit() {
      this.isActiveTherapyEditTableScheme = true
		},
  }
}
</script>

<style scoped lang="scss">
	
	.margin-3 {
		margin-bottom: 3px;
	}
	
	.margin-padding-0 {
		margin: 0;
		padding: 0;
	}
	
	
	/deep/ .el-tabs__header {
		margin-bottom: 0;
		padding: 20px 0 0 20px;
	}
	
	/deep/ .el-drawer {
		bottom: 0 !important;
	}
	
	/deep/ .el-drawer__header {
		padding: 0;
		margin: 0;
	}
	
	/deep/ .el-drawer.rtl {
		overflow: visible;
		height: 91vh;
		bottom: 0;
		margin-top: 9vh;
		position: absolute;
	}
	
	/deep/ .el-drawer__container ::-webkit-scrollbar {
		display: none;
	}
	
	/*tabs 样式*/
	::v-deep.el-tabs {
		.el-tabs__item {
			font-size: 16px;
		}
		
		.el-tabs__item.is-active {
			font-weight: bolder;
			font-size: 16px;
			color: #2362fb;
		}
		
		.el-tabs__active-bar {
			font-size: 16px;
			background-color: #2362fb;
		}
	}
	
	
	/*鼠标滑动,详情颜色改变*/
	.is-hover {
		color: #333;
	}
	
	.is-hover:hover {
		color: #4ebafa
	}
	
	/*栅格文本居中*/
	.relation-container {
		display: flex;
		/*justify-content:center;!*主轴上居中*!*/
		align-items: center;
		/*侧轴上居中*/
	}
	
	.number-align {
		padding-top: 4px;
	}
	
	/*栅格边框*/
	.el-row {
		
		/*margin-bottom: 20px;*/
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.el-col {
		border-radius: 4px;
	}
	
	/deep/ .el-tab-pane {
		overflow: auto;
	}
	
	/*测试排版使用颜色*/
	/*.bg-purple-dark {*/
	/*	background: #99a9bf;*/
	/*}*/
	
	/*.bg-purple {*/
	/*	background: #d3dce6;*/
	/*}*/
	
	/*.bg-purple-light {*/
	/*	background: #e5e9f2;*/
	/*}*/
	
	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}
	
	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
	
	.color777 {
		color: #777;
	}
	
	
	/*.el-input {*/
	/*	width: 350px;*/
	/*}*/
	
	/deep/ .el-transfer {
		margin: 0 20px;
		
		.el-transfer-panel__body {
			height: 750px;
		}
		
		.el-transfer-panel__list {
			height: 750px;
		}
	}
</style>
