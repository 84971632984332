import request from '@/utils/request'

// 信息管理 - 问诊信息 - 问诊记录
export function interviewRecord(params) {
	return request({
		url: 'admin/therapy/chat',
		method: 'GET',
		params
	})
}

// 信息管理 - 问诊信息 - 电子处方
export function ePrescription(params) {
	return request({
		url: '/admin/pres/show/',
		method: 'GET',
		params
	})
}

